import ApiHelper from "@/api/ApiHelper"

export default {
    async reportById(table, reportId, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token, },
        };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
        })

        // return Api.post("/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table, 
        // data, headers);
        let url = "/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table;
        return await ApiHelper.getResult(url, data, headers);
    },
    async getOwnerByRid(rid) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.get("/v1/owners/" + rid + "", 
        // data, headers);
        let url = "/v1/owners/" + rid + "";
        return await ApiHelper.getResult(url, data, headers);
    },

    async deleteByRid(rid) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // return Api.delete("/v1/owners/rid/" + rid, 
        // data, headers);
        let url = "/v1/owners/rid/" + rid;
        return await ApiHelper.getResult(url, data, headers);
    }
}