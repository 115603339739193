<template>
  <div class="home">
    <!-- <div>{{ holidayRecords }}</div> -->
    <div class="filter">
      <div class="filter__wrap">
        <div class="filter__body">
          <div class="filter__title">
            <div class="filter__img"></div>
            <!-- <div class="filter__title-text">Filters</div> -->
          </div>
          <div class="filter__items">
            <div class="filter__item high-visibility" v-if="role == 'Admin'">
              <div v-if="chartererNameFilterValue.length > 0" class="filter__item-name">Charterer</div>
              <!-- <div class="filter__item-name">Charterer - Name</div> -->
              <Multiselect
                v-model="chartererNameFilterValue"
                :options="chartererNameFilter"
                mode="tags"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Charterer"
                :close-on-select="false"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>

            <div class="filter__item high-visibility">
              <!-- <div class="filter__item-name">Charterer - Name</div> -->
              <div v-if="vesselNameFilterValue.length > 0" class="filter__item-name">Vessel Name</div>
              <Multiselect
                v-model="vesselNameFilterValue"
                :options="vesselNameFilter"
                mode="tags"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Vessel Name"
                :close-on-select="false"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>

            <div class="filter__item medium-visibility">
              <!-- <div class="filter__item-name">Load Ports</div> -->
              <div v-if="ownerFilterValue.length > 0" class="filter__item-name">Owner Name</div>
              <Multiselect
                v-model="ownerFilterValue"
                :options="ownerFilter"
                mode="tags"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Owner Name"
                :close-on-select="false"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>

            <div class="filter__item medium-visibility">
              <!-- <div class="filter__item-name">Load Ports</div> -->
              <div v-if="loadPortFilterValue.length > 0" class="filter__item-name">Load Port</div>
              <Multiselect
                v-model="loadPortFilterValue"
                :options="loadPortFilter"
                mode="tags"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Load Port"
                :close-on-select="false"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>
            <div class="filter__item low-visibility">
              <!-- <div class="filter__item-name">Status</div> -->
              <div v-if="statusFilterValue.length > 0" class="filter__item-name">Status</div>
              <Multiselect
                v-model="statusFilterValue"
                :options="statusFilter"
                mode="tags"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Status"
                :preselect-first="true"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>
          </div>
          <div class="btn-apply">
            <div class="btn-apply__body" v-on:click="filterApply">
              <custom-loader v-if="filterApplying" />
              <span v-else>Apply</span>
            </div>
          </div>
        </div>
        <div class="filter__footer"></div>
      </div>
    </div>

    <!-- <QbTables @getInfo="getInfoTable" :selectedTable="this.table"/> -->
    <!-- <QbReports @getInfo="getInfoReport" :table="this.table" :selectedReport="this.report"/> -->

    <div v-if="this.addLifting" class="wrap-create-button">
      <button
      class="create-record"
        @click="() => {this.$router.push({
            name: 'CreateLiftingRecord',
            params: {},
          });
        }
      "
      >New Lifting</button>
    </div>

    <div class="reports">
      <div class="reports__wrap">
        <div class="reports__body">
          <div 
            class="report__item"
            v-for="reportItem in reportsData"
            :key="reportItem"
          >
            <div class="report__wrap" v-if="this.showReport(reportItem.filterName)">
              <!-- <div class="report__title">
                <h2>{{reportItem.name}}</h2>
              </div> -->
              <QbReport
                :editLifting="editLifting"
                :table=reportItem.tableId
                :report=reportItem.reportId
                :title=reportItem.name
                :filter="this.filterCollect"
                :showBodyProp=reportItem.showBody
                :stickyColumns="this.getStickyColumns(reportItem)"
              />
            </div>
          </div>

          <!-- <div class="report__item" v-if="this.showReport('Short')">
            <div class="report__title">
              <h2>Short</h2>
            </div>
            <QbReport
              :table="'brxisk55z'"
              :report="'27'"
              :filter="this.filterCollect"
            />
          </div> -->
        </div>
      </div>
    </div>

    <ShowBimcoHolidaysPopUp
      v-if="showModalAddBimcoHolidays"
      :open="showModalAddBimcoHolidays"
      :recordKey="keyForTasks"
      @showStatus="showStatusAddBimcoHolidaysPopUp"
    />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import QbTables from "@/components/tables/QbTables.vue";
// import QbReports from "@/components/reports/QbReports.vue";
import QbReport from "@/components/report/QbReport.vue";
import Multiselect from "@vueform/multiselect";
import CustomLoader from "@/components/loader/CustomLoader.vue";
import ShowBimcoHolidaysPopUp from "@/components/pop-up/ShowBimcoHolidaysPopUp.vue";
import { mapState, mapActions } from "vuex";
import Lifting from "@/api/Lifting";

import UserRepository from "@/api/User";

// import { reactive } from 'vue'
export default {
  name: "MainView",
  components: {
    // QbTables,
    // QbReports,
    QbReport,
    Multiselect,
    CustomLoader,
    ShowBimcoHolidaysPopUp,
  },
  data() {
    return {
      users: [],
      addLifting: 0,
      editLifting: 0,
      table: "",
      report: "",
      chartererNameFilter: [],
      loadPortFilter: [],
      ownerFilter: [],
      vesselNameFilter: [],
      statusFilter: [],
      chartererNameFilterValue: [],
      loadPortFilterValue: [],
      ownerFilterValue: [],
      vesselNameFilterValue: [],
      statusFilterValue: [],
      filterApplying: false,
      filterCollect: {},

      showModalAddBimcoHolidays: false,

      reportsData: [ // mapping for all reports
        {
          name: "Short",
          filterName: "Short",
          tableId: "brxisk55z",
          reportId: "27",
          showBody: true,
          stickyAdminColumns: 0,
          stickyColumns: 0,
        },
        {
          name: "TBN",
          filterName: "TBN",
          tableId: "brxisk55z",
          reportId: "28",
          showBody: true,
          stickyAdminColumns: 4,
          stickyColumns: 3,
        },
        {
          name: "Nominated",
          filterName: "Nominated",
          tableId: "brxisk55z",
          reportId: "29",
          showBody: true,
          stickyAdminColumns: 2,
          stickyColumns: 1,
        },
        {
          name: "At Load",
          filterName: "Loading",
          tableId: "brxisk55z",
          reportId: "33",
          showBody: true,
          stickyAdminColumns: 2,
          stickyColumns: 1,
        },
        {
          name: "Sailing",
          filterName: "Sailing",
          tableId: "brxisk55z",
          reportId: "30",
          showBody: true,
          stickyAdminColumns: 2,
          stickyColumns: 1,
        },
        {
          name: "Discharge",
          filterName: "Discharge",
          tableId: "brxisk55z",
          reportId: "31",
          showBody: true,
          stickyAdminColumns: 2,
          stickyColumns: 1,
        },
        {
          name: "Completed / Laytime",
          filterName: "Completed",
          tableId: "brxisk55z",
          reportId: "32",
          showBody: true,
          stickyAdminColumns: 2,
          stickyColumns: 1,
        },
        /* {
        {
          name: "Perdure Norfolk",
          filterName: "Closed",
          tableId: "brxisk55z",
          reportId: "44",
        },
        {
          name: "Zen-noh Convent",
          filterName: "Closed",
          tableId: "brxisk55z",
          reportId: "43",
        }, */
        {
          name: "Final Freight Paid",
          filterName: "Final Freight Paid",
          tableId: "brxisk55z",
          reportId: "87",
          showBody: false,
          stickyAdminColumns: 2,
          stickyColumns: 1,
        },
        {
          name: "Closed / Archived",
          filterName: "Closed / Archived",
          tableId: "brxisk55z",
          reportId: "88",
          showBody: false,
          stickyAdminColumns: 0,
          stickyColumns: 0,
        },
      ],
      // {
      //   id: ""
      // }

      lastActiveTime: (new Date()).getTime(),
      updatePageAfter: 60*5*1000,
    };
  },
  created() {
    // if(!this.$store.holidays) this.refresh();
  },
  beforeMount() {
    console.log("beforeMount");
    if (!localStorage.token) {
      this.$router.push("/signin");
      // this.name = localStorage.name;
    }
  },
  mounted() {
    this.getAllUsers();
    this.getFiltersData();
    // this.checkUserActive();
    // this.$store.dispatch('holidays/getHolidays')
  },
  computed: {
    // ...mapState(["holidayRecords"]),
    ...mapState("holidays", {
       holidayReportData: state => state.holidayReportData,

    }),
    /* ...mapState({
        // name: state=>state.name,
        // weatherData: state=>state.yearData
    }), */
    role() {
      if(localStorage.role) {
        return localStorage.role;
      } else {
        return "";
      }
    },
    isAdmin() {
      if(this.role == "Admin") {
        return true;
      } else {
        return false;
      }
    }
    // filterCollect() {
    //   let res = {};
    //   res["97"] = this.chartererNameFilterValue;
    //   res["149"] = this.loadPortFilterValue;
    //   res["42"] = this.statusFilterValue;
    //   return res;
    // },
  },
  methods: {
    ...mapActions('holidays', ["getHolidays", "getCount"]),

    getAllUsers() {
      UserRepository.getUsers()
        .then((result) => {
          this.users = result["data"]["data"]

          this.addEditLifting()

          console.log("Users", result["data"]);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    addEditLifting() {
      let emailLocalStorage = localStorage.getItem('email') || ''; 

      let loggedUser = this.users.find(user => user.email === emailLocalStorage);

      this.addLifting = loggedUser.add_lifting
      this.editLifting = loggedUser.edit_lifting

      console.log("LOGED-ADD", this.addLifting)
      console.log("LOGED-EDIT", this.editLifting)
    },

    checkUserActive() {
      let app = document.querySelector(
        "#app"
      );

      app.addEventListener('mousemove', this.mouseMonitor);

      setInterval(this.updatePage, 5000);
    },

    async mouseMonitor() {
      this.lastActiveTime = (new Date()).getTime();
    },

    async updatePage() {
      if((this.lastActiveTime + this.updatePageAfter) < (new Date()).getTime()) {
        // location.reload();
      }
    },

    showStatusAddBimcoHolidaysPopUp(status) {
      this.showModalAddBimcoHolidays = status;
    },

    getStickyColumns(reportItem) {
      return this.isAdmin ? reportItem.stickyAdminColumns : reportItem.stickyColumns;
    },
    
    showReport(name) {
      console.log(name);
      if (this.statusFilterValue.length == 0) {
        return true;
      } else {
        for (const key in this.statusFilterValue) {
          console.log("====");
          console.log(this.statusFilterValue[key]);
          console.log(name.toLowerCase());
          console.log(this.statusFilterValue[key]
              .toLowerCase()
              .indexOf(name.toLowerCase()) != -1);
          if (
            this.statusFilterValue[key]
              .toLowerCase()
              .indexOf(name.toLowerCase()) != -1
          ) {
            return true;
          }
          // return false;
          // if (Object.hasOwnProperty.call(statusFilterValuel, key)) {
          //   const element = statusFil[key];

          // }
        }
        return false;
      }
      /* statusFilterValuel.forEach(el => {
        if(statusFilterValuel.indexOf(name) != -1) {
          return true;
        }
      });
      if(statusFilterValuel.indexOf(name) != -1) {
        return true;
      } */
    },
    logout() {
      localStorage.token = "";
      this.$router.push("/signin");
    },
    filterApply() {
      let res = {};
      res["373"] = this.chartererNameFilterValue;
      res["149"] = this.loadPortFilterValue;
      res["42"] = this.statusFilterValue;
      res["92"] = this.ownerFilterValue;
      res["96"] = this.vesselNameFilterValue;
      this.filterCollect = res;
    },
    getInfoTable(table) {
      console.log("table");
      console.log(table);
      this.table = table.id;
      this.report = "";
      // this.table.id = table.id;
      // this.table.name = table.name;
    },
    getInfoReport(report) {
      console.log("report");
      console.log(report);
      this.report = report.id;
      // this.table.id = table.id;
      // this.table.name = table.name;
    },

    getFiltersData() {
      if (!this.holidayReportData.length) {
          this.getHolidays(6);
      }
      Lifting.getFilterData()
        .then((result) => {
          console.log("Lifting.getFilterData");
          console.log(result);
          result = result["data"];
          // this.clients = result;
          this.chartererNameFilter = result["body"]["data"]["373"];
          this.loadPortFilter = result["body"]["data"]["149"];
          this.statusFilter = result["body"]["data"]["42"];
          this.ownerFilter = result["body"]["data"]["92"];
          this.vesselNameFilter = result["body"]["data"]["96"];
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<style scoped src="@/styles/views/main.css"></style>

<style>
body::-webkit-scrollbar {
  width: 16px;
  height: 10px;
  background-color: #f9f9fd;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.wrap-create-button {
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
}
.create-record, .btn-apply__body {
  border: 1px solid transparent;
  box-sizing: border-box;
}

.create-record:hover, .btn-apply__body:hover {
  background-color: #50629b;
  border: 1px solid transparent;
  outline: none;
}

.high-visibility {
  z-index: 2;
}

.medium-visibility {
  z-index: 1;
}

.low-visibility {
  z-index: 0;
}

</style>
<style src="@vueform/multiselect/themes/default.css"></style>
