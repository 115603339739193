<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__header-content">
              <div class="wrap-header-multiselect">
                <div class="switcher-content__data-multiselect charterer-name">
                  <Multiselect
                    v-model="this.formData.fid372" 
                    :options="charterers"
                    label="value" 
                    track-by="id"
                    valueProp="id" 
                    :searchable="true"
                    placeholder="Charterer - Name"                  
                  />
                </div>
                <div class="switcher-content__data-multiselect status">
                  <Multiselect
                    v-model="formData.fid42"
                    :options="sortSelect(allSelect['Status'])"
                    :searchable="true"
                    placeholder="Status"
                  />
                </div>
              </div>
              <div class="modal__header-panel">
                <div
                  class="modal__close-button"
                  v-on:click="
                    () => this.$router.push('/')
                  "
                >
                  <div class="close-button__img"></div>
                  <span>Cancel</span>
                </div>
                <div class="modal__save-button" v-on:click="() => this.sendToQB()">
                  <CustomLoader v-if="sendingToQB" />
                  <div v-else class="save-button__body">
                    <div class="save-button__img"></div>
                    <span>Save</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <template v-for="(elem, index) in mapping" :key="index">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="selectedSwitcher = index"
                  v-if="isShowTab(elem)"
                >
                  {{ elem }}
                </div>
              </template>

              <!-- <div class="switch-bar__item google-drive" v-if="mappingNotEmpty"> -->
              <div class="switch-bar__item google-drive">
                <div class="google-drive__left-img"></div>
                <a target="_blank" href="#">Google Drive</a>
              </div>

            </div>
            <div class="switcher-content">

              <div class="" v-if="mapping[selectedSwitcher] == 'Nomination'">
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Contract # and Type.</div>
                            <div>
                              <textarea
                                  v-model="formData.contractAndType"
                                  cols="30"
                                  rows="1"
                                  class="switcher-content__text-data-textarea"
                                  :disabled="true"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight summary</div>
                            <div>
                              <textarea
                                  v-model="formData.freightSummary"
                                  cols="30"
                                  rows="1"
                                  class="switcher-content__text-data-textarea"
                                  :disabled="true"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td v-if="!showShipFields">
                          <div>
                            <div class="switcher-content__title">Vessel - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.fid95"
                                  :options="sortOptionsById(tablesOptions.brxis6w8r, 6)"
                                  :searchable="true"
                                  :label="6"
                              />
                            </div>
                          </div>
                        </td>
                        <td v-if="showShipFields">
                          <div>
                            <div class="switcher-content__title">Name</div>
                            <div>
                              <input
                                v-model="formData.fid1171"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td class="wrap-new-ship-button">
                          <button
                            class="new-ship-button"
                            @click="showHideShipFields"
                          >
                            {{ showShipFields ? 'Cancel Ship' : 'New Ship' }}
                          </button>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Accepted</div>
                            <div>
                              <input
                                v-model="formData.fid771"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid771"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel name (formula)</div>
                            <div>
                              <input
                                v-model="formData.vesselNameFormula"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Nomination Time</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid807"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Appropriation To Buyers Done</div>
                            <div>
                              <input
                                v-model="formData.fid1012"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid1012"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!showShipFields">
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - DWT</div>
                            <div>
                              <input
                                v-model="formData.vesselDWT"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - LOA</div>
                            <div>
                              <input
                                v-model="formData.vesselLOA"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - Beam</div>
                            <div>
                              <input
                                v-model="formData.vesselBeam"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!showShipFields">
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - IMO</div>
                            <div>
                              <input
                                v-model="formData.vesselIMO"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - Age</div>
                            <div>
                              <input
                                v-model="formData.vesselAge"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - Flag</div>
                            <div>
                              <input
                                v-model="formData.vesselFlag"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="showShipFields">
                        <td>
                          <div>
                            <div class="switcher-content__title">DWT</div>
                            <div>
                              <input
                                v-model="formData.fid1172"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">LOA</div>
                            <div>
                              <input
                                v-model="formData.fid1173"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Beam</div>
                            <div>
                              <input
                                v-model="formData.fid1174"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="showShipFields">
                        <td>
                          <div>
                            <div class="switcher-content__title">IMO</div>
                            <div>
                              <input
                                v-model="formData.fid1175"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Built</div>
                            <div>
                              <input
                                v-model="formData.fid1176"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Age</div>
                            <div>
                              <input
                                v-model="resultYear"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Flag</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1177"
                                :options="sortOptionsById(tablesOptions.br3isn8nb, 6)"
                                :label="6"               
                                :track-by="3"            
                                :valueProp="6"           
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                     
                      </tr>
                      <!-- <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Head Owner - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.fid543"
                                  :options="sortOptionsById(tablesOptions.brxixht2m, 6)"
                                  :searchable="true"
                                  :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr> -->
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP - Chain</div>
                            <div>
                              <textarea
                                  v-model="formData.fid100"
                                  cols="20"
                                  rows="5"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Last Cargo</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.fid463"
                                  :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                  :searchable="true"
                                  :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cleanliness</div>
                            <div>
                              <input
                                v-model="formData.cleanliness"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Previous Port Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.fid1091"
                                  :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                                  :searchable="true"
                                  :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Demurrage</div>
                            <div>
                              <input
                                v-model="formData.fid101"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Demurrage Alert</div>
                            <div>
                              <input
                                v-model="formData.demurrageAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Vessel Specs Check</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CNF Specs Summary</div>
                            <div>
                              <input
                                v-model="formData.cnfSpecsSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cnf Alert</div>
                            <div>
                              <input
                                v-model="formData.cnfAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Vessels Specs Summary</div>
                            <div>
                              <input
                                v-model="formData.freightVesselsSpecsSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Alert</div>
                            <div>
                              <input
                                v-model="formData.freightAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Port Specs Summary</div>
                            <div>
                              <input
                                v-model="formData.dischargePortSpecsSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Alert</div>
                            <div>
                              <input
                                v-model="formData.dischargeAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Arrival Itinerary</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Itinerary</div>
                            <div>
                              <textarea
                                  v-model="formData.fid105"
                                  cols="20"
                                  rows="5"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">AIS Summary</div>
                            <div>
                              <input
                                v-model="formData.aisSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETA - Load Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid106"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Shakey ETA</div>
                            <div>
                              <input
                                v-model="formData.fid1170"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid1170"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Update ETA - Load Port</div>
                            <div>
                              <input
                                v-model="formData.updateEtaLoadPort"
                                type="text"
                                class="switcher-content__data-input checkbox"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETB - Load Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid155"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETC - Load Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid117"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Update ETC - Load Port</div>
                            <div>
                              <input
                                v-model="formData.updateEtcLoadPort"
                                type="text"
                                class="switcher-content__data-input checkbox"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laycan</div>
                            <div>
                              <input
                                v-model="formData.laycan"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Sailing Itinerary</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Arrived Load 1 Timestamp</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid153"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Completed Load 1 Timestamp</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid157"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days at load</div>
                            <div>
                              <input
                                v-model="formData.daysAtLoad"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Punctuality</div>
                            <div>
                              <input
                                v-model="formData.punctuality"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETA - Discharge Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid107"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days at discharge 1</div>
                            <div>
                              <input
                                v-model="formData.daysAtDischarge1"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing time</div>
                            <div>
                              <input
                                v-model="formData.sailingTime"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Total days (Ports + Sailing)</div>
                            <div>
                              <input
                                v-model="formData.totalDaysPortsSailing"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days at discharge 2</div>
                            <div>
                              <input
                                v-model="formData.daysAtDischarge2"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing time to 2nd disport</div>
                            <div>
                              <input
                                v-model="formData.sailingTimeTo2ndDisport"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Total days (2 disports)</div>
                            <div>
                              <input
                                v-model="formData.totalDays2Disports"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETC (latter of disport 1 /2)</div>
                            <div>
                              <input
                                v-model="formData.etcLatterOfDisport12"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETB (latter of 2 disports)</div>
                            <div>
                              <input
                                v-model="formData.etbLatterOf2Disports"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Agents</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Agent Load</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid93"
                                :options="sortOptionsById(tablesOptions.brxi28a76, 6)"
                                :searchable="true"
                                :label="6"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Agent Discharge</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid323"
                                :options="sortOptionsById(tablesOptions.brxi28a76, 6)"
                                :searchable="true"
                                :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Estimated Intake & Pre-Stow</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 1</div>
                            <div>
                              <input
                                v-model="formData.fid102"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 1 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid495"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">2nd Estimated Intake</div>
                            <div>
                              <input
                                v-model="formData.fid474"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid474"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="formData.fid474">
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 2</div>
                            <div>
                              <input
                                v-model="formData.fid325"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 2 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid497"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">3rd Estimated Intake</div>
                            <div>
                              <input
                                v-model="formData.fid475"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid475"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="formData.fid475">
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 3</div>
                            <div>
                              <input
                                v-model="formData.fid327"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 3 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid499"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Calculated Intake</div>
                            <div>
                              <input
                                v-model="formData.calculatedIntake"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stem formula</div>
                            <div>
                              <input
                                v-model="formData.stemFormula"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cnf Disch Draft Summary</div>
                            <div>
                              <input
                                v-model="formData.cnfDischDraftSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 1</div>
                            <div>
                              <input
                                v-model="formData.fid333"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 1 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid508"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 2</div>
                            <div>
                              <input
                                v-model="formData.fid335"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 2 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid510"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 3</div>
                            <div>
                              <input
                                v-model="formData.fid501"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 3 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid512"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 4</div>
                            <div>
                              <input
                                v-model="formData.fid503"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 4 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid514"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 5</div>
                            <div>
                              <input
                                v-model="formData.fid504"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 5 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid516"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Total</div>
                            <div>
                              <input
                                v-model="formData.fid103"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Total Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid506"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre-stow accepted</div>
                            <div>
                              <input
                                v-model="formData.fid1135"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid1135"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Stow</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr v-if="!formData.stowTotalMT">
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 1</div>
                            <div>
                              <input
                                v-model="formData.fid104"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 1 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid527"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!formData.stowTotalMT">
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 2</div>
                            <div>
                              <input
                                v-model="formData.fid328"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 2 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid529"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!formData.stowTotalMT">
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 3</div>
                            <div>
                              <input
                                v-model="formData.fid331"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 3 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid531"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!formData.stowTotalMT">
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 4</div>
                            <div>
                              <input
                                v-model="formData.fid525"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 4 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid533"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!formData.stowTotalMT">
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 5</div>
                            <div>
                              <input
                                v-model="formData.fid524"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 5 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid535"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Total (MT)</div>
                            <div>
                              <input
                                v-model="formData.fid526"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Total Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid541"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Deadfreight</div>
                            <div>
                              <input
                                v-model="formData.fid706"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Additional Cargo</div>
                            <div>
                              <input
                                v-model="formData.additionalCargo"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">stow_calc</div>
                            <div>
                              <input
                                v-model="formData.stowCalc"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>   
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing Draft FWD (m)</div>
                            <div>
                              <input
                                v-model="formData.fid1104"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing Draft AFT (m)</div>
                            <div>
                              <input
                                v-model="formData.fid1105"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Est arrival draft FWD (m)</div>
                            <div>
                              <input
                                v-model="formData.fid1106"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Est arrival draft AFT (m)</div>
                            <div>
                              <input
                                v-model="formData.fid1107"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr> 
                    </table>
                  </div>
                </div>
              </div>
              <div class="" v-else-if="mapping[selectedSwitcher] == 'Freight Fixtures'">
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid338"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Broker</div>
                            <div>
                              <input
                                v-model="formData.fid365"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight contract #</div>
                            <div>
                              <input
                                v-model="formData.fid808"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Owner</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid91"
                                :options="sortOptionsById(tablesOptions.brxixht2m, 6)"
                                :searchable="true"
                                :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stem Size 1</div>
                            <div>
                              <input
                                v-model="formData.fid352"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Tolerance 1</div>
                            <div>
                              <input
                                v-model="formData.fid353"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cargo-Type Freight 1 - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid374"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">2nd Freight Cargo</div>
                            <div>
                              <input
                                v-model="formData.fid472"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid472"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="formData.fid472">
                        <td>
                          <div>
                            <div class="switcher-content__title">Stem Size 2</div>
                            <div>
                              <input
                                v-model="formData.fid366"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Tolerance 2</div>
                            <div>
                              <input
                                v-model="formData.fid368"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cargo-Type Freight 2 - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid377"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">3rd Freight Cargo</div>
                            <div>
                              <input
                                v-model="formData.fid473"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid473"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="formData.fid473">
                        <td >
                          <div>
                            <div class="switcher-content__title">Stem Size 3</div>
                            <div>
                              <input
                                v-model="formData.fid367"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Tolerance 3</div>
                            <div>
                              <input
                                v-model="formData.fid369"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cargo-Type Freight 3 - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid380"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Vessel</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max Dwt</div>
                            <div>
                              <input
                                v-model="formData.fid709"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max LOA</div>
                            <div>
                              <input
                                v-model="formData.fid710"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max Beam</div>
                            <div>
                              <input
                                v-model="formData.fid711"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max Age</div>
                            <div>
                              <input
                                v-model="formData.fid970"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight - OAP</div>
                            <div>
                              <textarea
                                  v-model="formData.fid971"
                                  cols="20"
                                  rows="10"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight - Gear</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid972"
                                :options="sortSelect(allSelect['Freight - Gear'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Load</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port formula</div>
                            <div>
                              <input
                                v-model="formData.loadPortFormula"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Layday</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid341"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cancelling</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid342"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Potential Layday</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid1024"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Potential Cancelling</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid1025"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Potential ETA</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid1026"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"  
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel</div>
                            <div>
                              <input
                                v-model="formData.fid346"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid486"
                                :options="sortSelect(allSelect['Excluded Time - Load 1 - Vessel'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid890"
                                :options="sortSelect(allSelect['Office Hours - Load 1 - Vessel'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid894"
                                :options="sortSelect(allSelect['Time Starts - Load 1 - Vessel'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <!-- <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Rate Choice</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1055"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td> -->
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Load Options</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Base</div>
                            <div>
                              <input
                                v-model="formData.fid1035"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Base</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1040"
                                :options="sortSelect(allSelect['Excluded Time - Load 1 - Vessel - Base'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Base</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1045"
                                :options="sortSelect(allSelect['Office Hours - Load 1 - Vessel - Base'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Base</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1050"
                                :options="sortSelect(allSelect['Time Starts - Load 1 - Vessel - Base'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 1</div>
                            <div>
                              <input
                                v-model="formData.fid1036"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 1</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1041"
                                :options="sortSelect(allSelect['Excluded Time - Load 1 - Vessel - Option 1'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 1</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1046"
                                :options="sortSelect(allSelect['Office Hours - Load 1 - Vessel - Option 1'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 1</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1051"
                                :options="sortSelect(allSelect['Time Starts - Load 1 - Vessel - Option 1'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 1 Diff</div>
                            <div>
                              <input
                                v-model="formData.fid1056"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 2</div>
                            <div>
                              <input
                                v-model="formData.fid1037"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 2</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1042"
                                :options="sortSelect(allSelect['Excluded Time - Load 1 - Vessel - Option 2'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 2</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1047"
                                :options="sortSelect(allSelect['Office Hours - Load 1 - Vessel - Option 2'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 2</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1052"
                                :options="sortSelect(allSelect['Time Starts - Load 1 - Vessel - Option 2'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 2 Diff</div>
                            <div>
                              <input
                                v-model="formData.fid1057"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 3</div>
                            <div>
                              <input
                                v-model="formData.fid1038"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 3</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1043"
                                :options="sortSelect(allSelect['Excluded Time - Load 1 - Vessel - Option 3'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 3</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1048"
                                :options="sortSelect(allSelect['Office Hours - Load 1 - Vessel - Option 3'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 3</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1053"
                                :options="sortSelect(allSelect['Time Starts - Load 1 - Vessel - Option 3'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 3 Diff</div>
                            <div>
                              <input
                                v-model="formData.fid1058"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 4</div>
                            <div>
                              <input
                                v-model="formData.fid1039"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 4</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1044"
                                :options="sortSelect(allSelect['Excluded Time - Load 1 - Vessel - Option 4'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 4</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1049"
                                :options="sortSelect(allSelect['Office Hours - Load 1 - Vessel - Option 4'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 4</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1054"
                                :options="sortSelect(allSelect['Time Starts - Load 1 - Vessel - Option 4'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 4 Diff</div>
                            <div>
                              <input
                                v-model="formData.fid1059"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 5</div>
                            <div>
                              <input
                                v-model="formData.fid1082"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 5</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1083"
                                :options="sortSelect(allSelect['Excluded Time - Load 1 - Vessel - Option 5'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 5</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1084"
                                :options="sortSelect(allSelect['Office Hours - Load 1 - Vessel - Option 5'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 5</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1085"
                                :options="sortSelect(allSelect['Time Starts - Load 1 - Vessel - Option 5'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 5 Diff</div>
                            <div>
                              <input
                                v-model="formData.fid1086"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Discharge</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Port formula</div>
                            <div>
                              <input
                                v-model="formData.dischargePortFormula"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Disch Rate 1 - Vessel</div>
                            <div>
                              <input
                                v-model="formData.fid347"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Disch 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid487"
                                :options="sortSelect(allSelect['Excluded Time - Disch 1 - Vessel'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Disch 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid981"
                                :options="sortSelect(allSelect['Office Hours - Disch 1 - Vessel'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Disch 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid895"
                                :options="sortSelect(allSelect['Time Starts - Disch 1 - Vessel'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight guaranteed draft (m)</div>
                            <div>
                              <input
                                v-model="formData.fid718"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Guaranteed Draft Salinity</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid751"
                                :options="sortSelect(allSelect['Freight Guaranteed Draft Salinity'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Declaration of Discharge Port</div>
                            <div>
                              <input
                                v-model="formData.fid983"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Lightening Clause</div>
                            <div>
                              <textarea
                                  v-model="formData.fid984"
                                  cols="20"
                                  rows="5"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Laytime</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laytime - Shifting Time to Berth</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid979"
                                :options="sortSelect(allSelect['Laytime - Shifting Time to Berth'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laytime - Shifting First to Second</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid980"
                                :options="sortSelect(allSelect['Laytime - Shifting First to Second'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laytime - Draft Survey Time</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid981"
                                :options="sortSelect(allSelect['Laytime - Draft Survey Time'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Time Ceases</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid982"
                                :options="sortSelect(allSelect['Freight Time Ceases'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Freight</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Rate</div>
                            <div>
                              <input
                                v-model="formData.fid344"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Initial Freight</div>
                            <div>
                              <input
                                v-model="formData.fid714"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Additional Cargo Freight</div>
                            <div>
                              <input
                                v-model="formData.fid708"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight m2m</div>
                            <div>
                              <input
                                v-model="formData.fid762"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Freight Differential</div>
                            <div>
                              <input
                                v-model="formData.fid355"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Freight Differential</div>
                            <div>
                              <input
                                v-model="formData.fid356"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Fumigation Removal Cost (-ve)</div>
                            <div>
                              <input
                                v-model="formData.fid701"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Misc Costs</div>
                            <div>
                              <input
                                v-model="formData.fid702"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Max Demurrage</div>
                            <div>
                              <input
                                v-model="formData.fid345"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Brokerage</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid587"
                                :options="sortSelect(allSelect['Brokerage'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Differentials</div>
                            <div>
                              <textarea
                                v-model="formData.fid761"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Ops to do:</div>
                            <div>
                              <textarea
                                v-model="formData.fid936"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Traffic Light</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1160"
                                :options="sortSelect(allSelect['Traffic Light'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td> 
                        <td>
                          <div>
                            <div class="switcher-content__title">Flag +</div>
                            <div>
                              <input
                                v-model="formData.flagPlus"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Flag Notes</div>
                            <div>
                              <input
                                v-model="formData.fid1164"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Notes</div>
                            <div>
                              <textarea
                                v-model="formData.fid343"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Urgent Note</div>
                            <div>
                              <input
                                v-model="formData.fid803"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.fid803"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Reminders</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Narrowing Days</div>
                            <div>
                              <input
                                v-model="formData.fid349"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laycan Narrowing Window</div>
                            <div>
                              <input
                                v-model="formData.fid359"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Nom Days</div>
                            <div>
                              <input
                                v-model="formData.fid348"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Substitution Days Prior ETA</div>
                            <div>
                              <input
                                v-model="formData.fid360"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Fixture: Declare load rate</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid361"
                                :options="sortSelect(allSelect['Fixture: Declare load rate'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Fixture: Declare Discharge Rate</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fid1006"
                                :options="sortSelect(allSelect['Fixture: Declare Discharge Rate'])"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Draw up CP days</div>
                            <div>
                              <input
                                v-model="formData.fid362"
                                type="number"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP Signed Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.fid812"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days to Signature</div>
                            <div>
                              <DatePicker
                                v-model="formData.daysToSignature"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP Notes</div>
                            <div>
                              <textarea
                                v-model="formData.fid652"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Tasks</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Narrowing Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.narrowingDate"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Nomination Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.nominationDate"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="" v-else-if="mapping[selectedSwitcher] == 'Ports'">
                <table class="switcher-content__sub-tab-table">
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Load Port 1</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid137"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Load Port 2</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid271"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Load Country</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid147"
                            :options="sortOptionsById(tablesOptions.br3isn8nb, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Way Point 1 Port Name</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid141"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Way Point 2 Port Name</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid143"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Port 1</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid139"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Port 2</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid272"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Port 3</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid1168"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Country</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid145"
                            :options="sortOptionsById(tablesOptions.br3isn8nb, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Related Discharge Port</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.fid139"
                            :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                            :searchable="true"
                            :label="6"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="" v-else-if="mapping[selectedSwitcher] == 'Accounting'">
                <table class="switcher-content__sub-tab-table">
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Freight Invoiced</div>
                        <div>
                          <input
                            v-model="formData.fid363"
                            type="number"
                            class="switcher-content__data-input"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Due owners</div>
                        <div>
                          <input
                            v-model="formData.dueOwners"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Initial Freight Paid</div>
                        <div>
                          <input
                            v-model="formData.fid766"
                            type="number"
                            class="switcher-content__data-input"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Initial Freight (bs rate * final stow)</div>
                        <div>
                          <input
                            v-model="formData.initialFreightBsRateFinalStow"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Initial Freight Brokerage @ 1.25%</div>
                        <div>
                          <input
                            v-model="formData.initialFreightBrokerage1point25"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Diff's Brokerage</div>
                        <div>
                          <input
                            v-model="formData.diffsBrokerage"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Total Brokerage / estimated for TBN</div>
                        <div>
                          <input
                            v-model="formData.totalBrokerageEstimatedForTBN"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Final Freight Formula</div>
                        <div>
                          <textarea
                            v-model="formData.finalFreightFormula"
                            cols="20"
                            rows="5"
                            class="switcher-content__text-data-textarea"
                            :disabled="true"
                          ></textarea>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">IFI Ttl</div>
                        <div>
                          <input
                            v-model="formData.ifiTtl"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">FFI Paid?</div>
                        <div>
                          <input
                            v-model="formData.fid782"
                            type="checkbox"
                            class="switcher-content__data-input checkbox"
                            :checked="formData.ffiPaid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Acc Comments</div>
                        <div>
                          <textarea
                            v-model="formData.fid819"
                            cols="20"
                            rows="5"
                            class="switcher-content__text-data-textarea"
                          ></textarea>
                        </div>
                      </div>
                    </td>
                  </tr>

                </table>
              </div>
              <div class="" v-else-if="mapping[selectedSwitcher] == 'Laytime'">
                <div class="switcher-content__sub-tab-title">Facts</div>
                <div>
                  <table class="switcher-content__sub-tab-table">

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Pre-Advise Expiry</div>
                          <div>
                            <input
                              v-model="formData.preAdviseExpiry"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Layday</div>
                          <div>
                            <input
                              v-model="formData.laytimeLayday"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Dem / Des</div>
                          <div>
                            <input
                              v-model="formData.demDes"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Load Summary</div>
                          <div>
                            <textarea
                              v-model="formData.loadSummary"
                              cols="20"
                              rows="5"
                              class="switcher-content__text-data-textarea"
                              :disabled="true"
                            ></textarea>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Laytime bss 10x</div>
                          <div>
                            <input
                              v-model="formData.fid994"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Disch Summary</div>
                          <div>
                            <textarea
                              v-model="formData.dischSummary"
                              cols="20"
                              rows="5"
                              class="switcher-content__text-data-textarea"
                              :disabled="true"
                            ></textarea>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="switcher-content__sub-tab-title">Vessel Laytime</div>
                <div>
                  <table class="switcher-content__sub-tab-table">
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Load 1:</div>
                          <div>
                            <input
                              v-model="formData.load1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.fid282"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.loadModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Owners</div>
                          <div>
                            <input
                              v-model="formData.fid285"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.loadModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.loadResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Disch 1:</div>
                          <div>
                            <input
                              v-model="formData.disch1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.fid288"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.dischModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Owners</div>
                          <div>
                            <input
                              v-model="formData.fid290"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.dischModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.dischResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">CNF spread: Owrs vs Buyers</div>
                          <div>
                            <input
                              v-model="formData.cnfSpreadOwrsVsBuyers"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="switcher-content__sub-tab-title">Commodity Laytime</div>
                <div>
                  <table class="switcher-content__sub-tab-table">
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Load 1:</div>
                          <div>
                            <input
                              v-model="formData.commodityload1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.fid280"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.commodityLoadModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Shippers</div>
                          <div>
                            <input
                              v-model="formData.fid278"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.commodityLoadModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.commodityLoadResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Disch 1:</div>
                          <div>
                            <input
                              v-model="formData.commodityDisch1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.fid294"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.commodityDischModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Buyers</div>
                          <div>
                            <input
                              v-model="formData.fid292"
                              type="number"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.commodityDischModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.commodityDischResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Laytime notes</div>
                          <div>
                            <textarea
                              v-model="formData.fid646"
                              cols="20"
                              rows="5"
                              class="switcher-content__text-data-textarea"
                            ></textarea>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </table>
                </div>
              </div>
              <div class="" v-else-if="mapping[selectedSwitcher] == 'Commodity Contract'">
                <table class="switcher-content__sub-tab-table">
                  <tr>
                    <td>
                      <div>
                        <div class="field-title"></div>
                        <div class="url-btn" style="background-color: rgb(222, 236, 252); color: black">
                          <div>Add Sale: Cnf Contract</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="field-title"></div>
                        <div class="url-btn" style="background-color: rgb(185, 206, 235); color: black">
                          <div>Add Sale: Fob Contract</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="field-title"></div>
                        <div class="url-btn" style="background-color: rgb(195, 180, 210); color: black">
                          <div>Add Purchase: Cnf Contract</div>
                        </div>
                      </div>
                    </td>
                    <td>
                     <div>
                       <div class="field-title"></div>
                       <div class="url-btn" style="background-color: rgb(155, 180, 96); color: black">
                         <div>Add Purchase: Fob Contract</div>
                       </div>
                     </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="modal__footer">
          </div>
        </div>
        <!-- <div v-else  class="modal__container-loader">
          <CustomLoader/>
        </div> -->
      </div>
    </div>
    
    <CreateRecordPopUp
      v-if="showCreateRecordPopUp"
      record-name="Lifting"
      :open="showCreateRecordPopUp"
      :createdRecord="this.createdRecord"
    />

  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import CustomLoader from "@/components/loader/CustomLoader.vue";
import CreateRecordPopUp from "@/components/pop-up/CreateRecordPopUp.vue";
// import { reactive } from "@vue/reactivity";
import { reactive } from "vue";
import { mapState } from "vuex";
import liftingFieldsModel  from "@/model/liftingFields";
import QuickbaseRepository from "@/api/Quickbase";

export default {
  name: "CreateLiftingRecord",
  components: {
    Multiselect,
    CustomLoader,
    CreateRecordPopUp,
  },
  props: {
    // table: String, // delete
    // selectedReport: String, // delete
    open: Boolean, // delete
    rid: Number,
  },
  emits: ["showStatus", "updateRecord"],
  setup() {
    let dataForDropDownFields = reactive({
      bsghxwya8: {},
      br6kf4scx: {},
      br3isn8nb: {},
      br3isji2e: {},
      brx74qi6y: {},
      brxi28a76: {},
      bsud3n4i3: {},
      bsuecfn4c: {},
    });
    return {
      dataForDropDownFields,
    };
  },
  
  data: function () {
    return {
      formData: {

        fid372: "", // Charterer - Name
        fid42: "", // Status

        // Nomination
        fid95: "",
        fid771: "",
        fid807: "",
        fid1012: "",
        // fid543: "",
        fid1171: "",
        fid1172: "",
        fid1173: "",
        fid1174: "",
        fid1175: "",
        fid1176: "",
        fid1177: "",
        fid100: "",
        fid463: "",
        fid1091: "",
        fid101: "",
        fid105: "",
        fid106: "",
        fid1170: "",
        fid155: "",
        fid117: "",
        fid153: "",
        fid157: "",
        fid107: "",
        fid93: "",
        fid323: "",
        fid102: "",
        fid495: "",
        fid474: "",
        fid325: "",
        fid497: "",
        fid475: "",
        fid327: "",
        fid499: "",
        fid333: "",
        fid335: "",
        fid501: "",
        fid503: "",
        fid504: "",
        fid103: "",
        fid508: "",
        fid510: "",
        fid512: "",
        fid514: "",
        fid516: "",
        fid506: "",
        fid1135: "",
        fid104: "",
        fid328: "",
        fid331: "",
        fid525: "",
        fid524: "",
        fid526: "",
        fid527: "",
        fid529: "",
        fid531: "",
        fid533: "",
        fid535: "",
        fid541: "",
        fid706: "",
        fid1104: "",
        fid1105: "",
        fid1106: "",
        fid1107: "",

        // Freight Fixtures
        fid338: "",
        fid365: "",
        fid808: "",
        fid91: "",
        fid352: "",
        fid353: "",
        fid374: "",
        fid472: "",
        fid366: "",
        fid368: "",
        fid377: "",
        fid473: "",
        fid367: "",
        fid369: "",
        fid380: "",
        fid709: "",
        fid710: "",
        fid711: "",
        fid970: "",
        fid971: "",
        fid972: "",
        fid341: "",
        fid342: "",
        fid1024: "",
        fid1025: "",
        fid1026: "",
        fid346: "",
        fid486: "",
        fid890: "",
        fid894: "",
        // fid1055: "", // don't work
        fid1035: "",
        fid1040: "",
        fid1045: "",
        fid1050: "",
        fid1036: "",
        fid1041: "",
        fid1046: "",
        fid1051: "",
        fid1056: "",
        fid1037: "",
        fid1042: "",
        fid1047: "",
        fid1052: "",
        fid1057: "",
        fid1038: "",
        fid1043: "",
        fid1048: "",
        fid1053: "",
        fid1058: "",
        fid1039: "",
        fid1044: "",
        fid1049: "",
        fid1054: "",
        fid1059: "",
        fid1082: "",
        fid1083: "",
        fid1084: "",
        fid1085: "",
        fid1086: "",
        fid347: "",
        fid487: "",
        fid891: "",
        fid895: "",
        fid718: "",
        fid751: "",
        fid983: "",
        fid984: "",
        fid979: "",
        fid980: "",
        fid981: "",
        fid982: "",
        fid344: "",
        fid714: "",
        fid708: "",
        fid762: "",
        fid355: "",
        fid356: "",
        fid701: "",
        fid702: "",
        fid345: "",
        fid587: "",
        fid761: "",
        fid936: "",
        fid1160: "",
        fid1164: "",
        fid343: "",
        fid803: "",
        fid349: "",
        fid359: "",
        fid348: "",
        fid360: "",
        fid361: "",
        fid1006: "",
        fid362: "",
        fid812: "",
        fid652: "",

        // Ports
        fid137: "",
        fid271: "",
        fid147: "",
        fid141: "",
        fid143: "",
        fid139: "",
        fid272: "",
        fid1168: "",
        fid145: "",
        // fid139: "", // fid139

        // Accounting
        fid363: "",
        fid766: "",
        fid782: "",
        fid819: "",

        // Laytime
        fid994: "",
        fid282: "",
        fid285: "",
        fid288: "",
        fid290: "",
        fid280: "",
        fid278: "",
        fid294: "",
        fid292: "",
        fid646: "",
      },
      charterers: [],
      allSelect: [],
      ttttt: liftingFieldsModel.fieldsMap,
      documentReportAllModel: [],
      documentReportAllModelOptions: [],
      documentReportAllModelOptions2: [
        {name: "test", rid: 1},
        {name: "test2", rid: 2},
      ],
      filterApplying: false,
      clistForTables: {
        brxis6w8r: [3, 6],
        brxixht2m: [3, 6],
        brx74qi6y: [3, 9],
        brxi28a76: [3, 6],
        br3isji2e: [3, 6],
        br3isn8nb: [3, 6],
      },
      tablesOptions: {
        brxis6w8r: [],
        brxixht2m: [],
        brx74qi6y: [],
        brxi28a76: [],
        br3isji2e: [],
        br3isn8nb: [],
      },
      reports: [],
      showShipFields: false,
      modalName: "Lifting Summary", // +  # " + this.rid,
      allFields: [],
      fields: [],
      masterTableId: "brxisk55z",
      sendingToQB: false,
      selectedSwitcher: 0,
      type: "",
      mapping: ["Nomination", "Freight Fixtures", "Ports", "Accounting", "Laytime", "Commodity Contract"],
      shipPaths: [],
      createdRecord: false,
      showCreateRecordPopUp: false,
    };
  },
  mounted() {
    this.getDataTaskFields();
    this.getRelData();
    this.getDataSelect();
  },
  updated() {
    let qbUpdateEtaButton = this.$el.querySelectorAll(
      ".qb-update-eta-button .Vibrant"
    );
    qbUpdateEtaButton.forEach((el) => {
      el.onclick = () => {
        this.updateETALoadPort(this.rid);
        return false;
      };
    });

    let qbUpdateEtcButton = this.$el.querySelectorAll(
      ".qb-update-etc-button .Vibrant"
    );
    qbUpdateEtcButton.forEach((el) => {
      el.onclick = () => {
        return false;
      };
    });

    let qbUpdateCompleteCurrentTaskButton = this.$el.querySelectorAll(
      ".qb-update-complete-current-task-button .Vibrant"
    );
    qbUpdateCompleteCurrentTaskButton.forEach((el) => {
      el.onclick = () => {
        return false;
      };
    });

    let allContracts = document.querySelectorAll(".rich-text__484 a");
    allContracts.forEach((el) => {
      el.onclick = () => {
        return false;
      };
    });

    var textarea = document.querySelectorAll(
      ".switcher-content__text-data-textarea"
    );
    textarea.forEach((el) => {
      // console.dir(el);
      // console.log(el.value);
      // console.log(el.scrollTop);
      // console.log(el.scrollHeight);
      // console.log(el.scrollWidth);

      el.style.height = 0;

      el.dataset.scrollTop = el.scrollTop;
      el.dataset.scrollHeight = el.scrollHeight;
      el.dataset.scrollWidth = el.scrollWidth;

      if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + 5 + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 20 + "px";
      }
      el.style.height = el.dataset.newHeight;
      el.style.width = el.dataset.newWidth;
      // }
    });
  },
  computed: {
    ...mapState({
       allHolidayFields: state => state.holidays.holidayRecords,
       holidayClist: state => state.holidays.holidayClist,
       holidayReportData: state => state.holidays.holidayReportData,
    }),

    resultYear() {
      const currentYear = new Date().getFullYear();
      return this.formData.fid1176 ? currentYear - this.formData.fid1176 : '';
    },

    mappingNotEmpty() {
      if (this.allFields.length == 0) return false;
      return true;
    },
    objAllFields() {
      let res = {};
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    valueType() {
      return {
        value2date: (value) => {
          return moment(value).format("LL");
        },
        date2value: (date) => {
          return moment(date).format("LL");
        },
      };
    },

  },
  watch: {

  },
  methods: {

    async getDataSelect() {
      var requestUsers = {
        method: "GET",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + `/v1/lifting/fields`,
        requestUsers
      )
        .then((response) => response.json())
        .then((result) => {
          this.allSelect = result
          this.getCharterers()
          
          console.log("Select", this.allSelect);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    getCharterers() {
      let emailLocalStorage = (localStorage.getItem('email') || '').toLowerCase();

      // console.log("emailLocalStorage", emailLocalStorage)

      if (this.allSelect['Charterer Name']) {
        this.charterers = this.allSelect['Charterer Name'][emailLocalStorage];
      }

      // console.log("CHARTERERS", this.charterers)
    },

    showHideShipFields() {
      this.showShipFields = !this.showShipFields;
      this.resetShipFields();
    },

    resetShipFields() {
      if (!this.showShipFields) {
        this.formData.fid1171 = "";
        this.formData.fid1172 = "";
        this.formData.fid1173 = "";
        this.formData.fid1174 = "";
        this.formData.fid1175 = "";
        this.formData.fid1176 = "";
        this.formData.fid1177 = "";
      }
      else {
        this.formData.fid95 = "";
      }
    },

    // 
    async sendToQB() {

      // console.log("TEST_FID_1177", this.formData.fid1177)

      this.sendingToQB = true;

      const data = Object.keys(this.formData).reduce((acc, key) => {
    
        const newKey = key.replace('fid', '');
   
        if (this.formData[key]) {
          if (newKey == 107 || newKey == 338 || newKey == 341 || newKey == 342 || newKey == 1024 || newKey == 1025 || newKey == 812) {
            acc[newKey] = moment( this.formData[key]).format("YYYY-MM-DD"); 
          } else {
            acc[newKey] = this.formData[key]; 
          }
        }

        return acc; 
    
      }, {});

      data[3] = "";

      let dataParam = {
        data: [data],
        to: this.masterTableId
      }

      console.log("dataParam", dataParam)

      this.saveDataForQB(dataParam);
    },

    sortSelect(items) {
      if (items) {
        return items.map(item => item.value).sort((a, b) => a.localeCompare(b)); 
      }
    },

    sortOptionsById(options, id) {
      const sorter = (a, b) => {
        const nameA = a[id].toUpperCase();
        const nameB = b[id].toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      };
      return options.sort(sorter);
    },

    async getDataForDropDownFieldsFromQB(table, clist) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          table: table,
          clist: clist,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/table/" + table + "/records",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          this.dataForDropDownFields[table] = data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getDataForDropDownField(table, fid) {
      console.log("getDataForDropDownField table:" + table + " fid:" + fid);
      let result = [];
      if (Object.hasOwnProperty.call(this.dataForDropDownFields, table)) {
        const data = this.dataForDropDownFields[table];
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const record = data[key];
            for (const fieldId in record) {
              if (Object.hasOwnProperty.call(record, fieldId)) {
                const element = record[fieldId];
                if (fieldId == fid) {
                  result.push(element["value"]);
                }
              }
            }
          }
        }
      }
      return result;
    },

    async getRelData() {
      for (const key in this.clistForTables) {
        if (Object.hasOwnProperty.call(this.clistForTables, key)) {
          this.getRelDataQB(key, this.clistForTables[key]);
        }
      }
    },
    async getRelDataQB(tableId, clist) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          clist: clist,
          token: localStorage.token,
        }),
      };
      console.log(requestOptions);

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/table/" + tableId + "/records",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          // let data = result["body"];
          console.log(data);
          let newRes = [];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];

              let tmp = {};
              for (const k in element) {
                if (Object.hasOwnProperty.call(element, k)) {
                  if (k == 3) {
                    tmp["value"] = element[k].value;
                  } else {
                    tmp[k] = element[k].value;
                  }
                }
              }
              newRes.push(tmp);
            }
          }

          this.tablesOptions[tableId] = newRes;

          /*
          :options="sortOptionsById(tablesOptions.brxi28a76, 6)"
          :searchable="true"
          :label="6"
          */
          
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
  
    isShowTab(value) {
      // if(this.fields.length == 0) return true;
      // "Nomination", "Freight Fixtures", "Ports", "Accounting", "Laytime", "Commodity Contract", "Dates", "", ""

      switch (value) {
        case "Nomination":
          if (
            this.formData.fid42 == "1. Short" ||
            this.formData.fid42 == "2. TBN"
          ) {
            // 1 4
            return false;
          } else {
            return true;
          }
          // break;
        case "Laytime":
          if (
            this.formData.fid42 == "1. Short" ||
            this.formData.fid42 == "2. TBN"
          ) {
            // 1 4
            return false;
          } else {
            return true;
          }
          // break;
        default:
          break;
      }
      if(value.show == false) return false;

      return true;
    },

    formatDateFunc(date, a) {
      console.log(a);
      if (date) {
        return moment(date).format("MM-DD-YYYY");
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date) {
      if (date) {
        return (
          moment(date)
            // .tz("US/Pacific")
            .format("MM-DD-yyyy h:mm a")
        ); // America/New_York
      } else {
        return "";
      }
    },

    getObjArrWithIdKey(fields) {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      fields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },

    getDataTaskFields() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          // "http://127.0.0.1:8000/api" +
          "/v1/qb/table/" +
          this.masterTableId +
          "/info",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.allFields = result["body"]["allFields"];
        })
        .catch((error) => console.log("error", error));
    },

    /**
     * Save all data to quickbases
     */
    async saveDataForQB(params) {
      console.log("TestParams", params)
      QuickbaseRepository.pushDataToQB(params)
        .then((result) => {
          console.log(result);

          this.createdRecord = true;
          this.showCreateRecordPopUp = true;

          setTimeout(() => {
            this.showCreateRecordPopUp = false;
            this.sendingToQB = false;
            this.$router.push('/');
          }, 3000);

          this.$emit("showStatus", false);
          this.$emit("updateRecord", this.rid);
        })
        .catch((error) => {
          this.sendingToQB = false;
          this.createdRecord = false;
          this.showCreateRecordPopUp = true;

          setTimeout(() => {
            this.showCreateRecordPopUp = false;
          }, 3000);
          
          console.log("error", error);
        });
    },


    updateETALoadPort(rid) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/button/eta/record/" + rid,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}

.switcher-content * {
  font-size: 14px;
}
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.modal__mask {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  flex-direction: column;
}
.modal__wrapper {
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.modal__container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal__container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}
.modal__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}
.modal__container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  background-color: #e3e9f1;
}
.modal__container-loader {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 1680px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    /* width: 1070px; */
  }
}

@media screen and (max-width: 1200px) {
  .modal__container {
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    width: 100%;
  }
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #d3dfed; */
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  flex: 0 0 auto;
}
.modal__header-content {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}
.modal__header-panel {
  display: flex;
}
.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}
.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}
.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}
.modal__default-button {
  float: right;
}
.modal__footer {
  display: flex;
  justify-content: space-between;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
  flex: 0 0 auto;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal__close-button:hover {
  background-color: #50629b;
  color: white;
}

.modal__close-button:hover .close-button__img {
  filter: invert(100%) brightness(1000%);
}

.close-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-close.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 58px;
}

.modal__save-button:hover {
  background-color: #50629b;
  outline: none;
}

.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-save.svg) no-repeat center center;
  margin-right: 10px;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

.switch-bar {
  display: flex;
  flex: 0 0 auto;
  background: #F6FAFF;
  padding: 0 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #D3DFED;
}

.switch-bar__item {
  /* flex: 1 1 auto; */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  margin-right: 15px;
  border: 1px solid transparent;
}

.switch-bar__item:hover {
  background: #ffffff;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switch-bar__item_active {
  /* border-bottom: 2px solid #2d3757; */
  
  background: #D3DFED;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switcher-content {
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.switcher-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.switcher-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.switcher-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.switcher-content__choose {
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.switcher-content__sub-tab-body {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: end; */
  /* overflow: auto; */
  /* overflow-x: auto; */
}

.disabled {
  background: #eee;
}

.switcher-content__sub-tab-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-bottom: #2d3757 1px solid;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
}

.switcher-content__item_25 {
  box-sizing: border-box;
  width: 25%;
  padding: 20px 20px;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.field-title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;

  display: flex;
  align-items: center;
}

.field-title__btn-log {
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50px;
  background:  #2d3757;
  color: #fff;
  padding: 2px 7px;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  min-width: 240px;
  border: 1px solid #d3dfed;
  border-radius: 2px;
  resize: none;
}

.switcher-content__text-data-textarea {
  /* box-sizing: content-box; */
  min-width: 240px;
  max-height: 538px;
  border: 1px solid #d3dfed;
  border-radius: 2px;
  display: flex;
  box-sizing: border-box;
  vertical-align: bottom;
  /* box-sizing: initial; */
  /* display: table-column; */
  padding: 10px;
  /* word-wrap: break-word; */
  white-space: pre;
  /* padding-bottom: 0; */
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__text-data-textarea.disabled {
  outline: none;
  /* border: none; */
  /* border-left: 1px solid #777; */
  /* border-right: 1px solid #777; */
  /* border-bottom: 1px solid #777; */
  /* margin: 0; */
  /* padding: 0; */
  /* position: absolute; */
  /* top: 40px; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0;  */
  /* width:-moz-availabel; height:100%;  */
  resize: none;
}

.url-btn {
  display: block;
  border: 1px solid #2d3757;
  background: #2d375715;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.url-btn:hover {
  color: #fff;
  background: #333;
}

.switcher-content__sub-tab-table {
  /* width: 100%; */
}

.switcher-content__sub-tab-table td {
  padding: 5px;
  border: 5px solid transparent;
  vertical-align: top;
}

.switcher-content__sub-tab-table td .field-title {
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}


.switcher-content__sub-tab-table td .switcher-content__data-input {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect,
.status.switcher-content__data-multiselect,
.charterer-name.switcher-content__data-multiselect {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect-document-filter {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 100%;
  max-width: 320px;
}


.switcher-content__sub-tab-table td .switcher-content__data-input.checkbox {
  width: auto;
}

.wrap-header-multiselect {
  display: flex;
  gap: 20px;
}

.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}


.btn-log {
}

.btn-log__body {
  display: none;
  width: max-content;
  position: absolute;
  background: #2d3757;
  color: #fff;
  z-index: 5;
  padding: 5px;
  left: 0;
  bottom: 30px;
  border-radius: 2px;
  box-shadow: -1px -1px 10px 0px #2d375780;
  border: 1px solid #d3dfed;
}

.btn-log:hover .btn-log__body {
  display: block;
}
td.wrap-new-ship-button {
  vertical-align: bottom;
}
.new-ship-button {
  width: 132px;
  padding: 11px 24px;
  background: #2d3757;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.new-ship-button:hover {
  background-color: #50629b;
  border: 1px solid transparent;
  outline: none;
}

</style>

<style>
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.dp-custom-input {
  font-size: 14px !important;
  width: auto !important;
}
.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}

.btn-apply {
   display: flex;
  margin-left: 20px;
  /* margin-top: 1px; */
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}

.report-table__item-text-multiple-choice-wrap {
  min-width: 240px;
}

.document-report-wrap {
  /* max-width: 1185px; */
  /* width: 1185px; */
}

/* .report-body {
  width: 1100px;
}

@media screen and (max-width: 1200px) {
  .report-body {
    width: 700px;
  }
} */


.google-drive {
  display: flex;
  align-items: center;
}

.google-drive__left-img {
  width: 16px;
  height: 16px;
  background: url(@/assets/link.svg) no-repeat center center;
  margin-right: 8px;
}

</style>

<style scoped>
.dp__input_wrap .dp__input {
  font-size: 14px;
  padding: 8px 12px;
  padding-left: 35px;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}

.multiselect {
  height: 43px !important;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}

</style>

<style scoped src="@vueform/multiselect/themes/default.css"></style>